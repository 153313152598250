<template>
  <v-card flat :min-height="height">
    <v-container fill-height class="pa-0">
      <v-row no-gutters :style="{'min-height': height}">

        <v-col cols="12" sm="12" md="12" lg="3" align-self="center">
          <v-card flat height="100%" :min-height="cardMinHeight" class="d-flex align-center">
            <v-container fluid fill-height>
              <v-row>
                <v-col>
                  <v-img src="assets/tokenfit-logo-min.svg" max-width="250px" class="auto-margin"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="3">
          <v-card flat height="100%" :min-height="cardMinHeight" class="rounded-0">
            <v-container fluid fill-height>
              <v-row>
                <v-col align-self="center">
                  <template v-for="(link, i) in secciones">

                    <!-- link para una página interna de vue -->
                    <router-link
                      v-if="link.to && !link.noFooter" 
                      :key="`link-${i}`"
                      :to="link.to" 
                      class="chivo d-block"
                      custom
                      v-slot="{ href, isExactActive }">
                      <a :href="href" :class="isExactActive ? 'turquesa--text' : 'white--text'">{{ $vuetify.lang.t(link.t) | capitalize }}</a>
                    </router-link>

                    <!-- link para una página externa -->
                    <a
                      v-if="link.href && !link.noFooter"
                      :key="`link-${i}`"
                      :href="link.href"
                      :target="link.target"
                      class="chivo white--text d-block">
                      {{ $vuetify.lang.t(link.t) | capitalize}}
                    </a>

                  </template>
                  <span class="white--text chivo d-block pointer" @click="showAvisoDialog">Aviso Legal</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="3">
          <v-card flat height="100%" :min-height="cardMinHeight" class="rounded-0">
            <v-container fluid fill-height>
              <v-row>
                <v-col>
                  <span class="d-block white--text chivo mb-5">Seguinos:</span>
                  <a :href="link.link" target="_blank" v-for="(link, i) in rrss" :key="`rrss-${i}`">
                    <v-img 
                      class="d-inline-flex ml-1 mr-1 image-as-button turquesa--text" 
                      :src="link.img" max-width="40px" />
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="3">
          <v-card flat height="100%" :min-height="cardMinHeight" class="rounded-0">
            <v-container fluid fill-height>
              <v-row>
                <v-col :class="$vuetify.breakpoint.mobile ? '' : 'text-right'"  align-self="center">
                  <span class="white--text chivo d-block">Rosario, Argentina</span>
                  <a text class="chivo turquesa--text" @click="openNewsDialog">Inscribite a nuestras NEWS</a>
                  <router-link
                    to="contacto" 
                    class="chivo d-block"
                    custom
                    v-slot="{ href }">
                    <a :href="href" class="turquesa--text"><span>Contactarnos</span></a>
                  </router-link>
                  <a href="mailto:contacto@tokenfit.io" class="d-block white--text body-2 pt-3"><span class="chivo">contacto@tokenfit.io</span></a>
                </v-col>
              </v-row>              
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <aviso-dialog />
  </v-card>
</template>

<script>
import EventBus from '@/utils/eventbus'
export default {
  name: 'ContactoRRSS',
  components: {
    AvisoDialog: () => import('@/views/Layout/AvisoDialog')
  },
  props: {
    secciones: {
      type: Array,
      default: () => []
    },
    rrss: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      height: '200px',
    }
  },
  computed: {
    cardMinHeight() {
      return this.$vuetify.breakpoint.mobile ? '130px' : '200px';
    }
  },
  methods: {
    openNewsDialog() {
      EventBus.$emit('show-news-dialog', {title: 'INSCRIBITE A NUESTRAS NEWS'})
    },
    showAvisoDialog() {
      EventBus.$emit('show-aviso-dialog')
    }
  }
}
</script>

<style>
.image-as-button {
  cursor: pointer;
}
</style>